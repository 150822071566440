
import PropTypes from 'prop-types'

import Loader from '../components/Loader'
import NoAnalytics from '../components/NoAnalytics'
import MetricGrid from '../components/MetricGrid'
import MetricCard from '../components/MetricCard'

import LastPosts from '../components/LastPosts'

import { capitalizeString } from 'lib/strings'
import { convertNumber } from 'lib/numbers'
import FollowersGrowth from '../components/FollowersGrowth'

const Tiktok = ({ data }) => {
    if (data === undefined) return <Loader />
    if (data === null) return <NoAnalytics vendor={'TikTok'} />

    const postsProps = {
        posts: data.postsList.slice(0, 12).reverse(),
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
            { key: 'shares', label: 'Shares' },
        ],
    }

    const followersProps = {
        growth: data.followersGrowth,
        count: data.followers,
        list: data.followersGrowthList,
        vendor: 'tiktok',
    }

    return (
        <div>
            <h1
                style={{
                    fontSize: 18,
                    color: '#888',
                    marginTop: 0,
                    marginBottom: 40,
                }}>
                {capitalizeString(data.name)} - TikTok Mediakit
            </h1>
            <MetricGrid columns={3}>
                <MetricCard
                    icon={'Profile'}
                    title={'Followers'}
                    value={convertNumber(data.followers)}
                />
                <MetricCard
                    icon={'Show'}
                    title={'Avg Views'}
                    value={convertNumber(data.views)}
                />
                <MetricCard
                    icon={'Heart'}
                    title={'Avg Engagement'}
                    value={convertNumber(data.interactions)}
                />
            </MetricGrid>
            {data.postsList && data.postsList.length
                ? (
                    <>
                        <div style={{ height: 60 }}/>
                        <LastPosts
                            {...postsProps}
                            openUrl={`https://www.tiktok.com/@${data.name}`}
                            vendor={'tiktok'}
                            username={data.name}
                        />
                    </>
                )
                : null}
            <FollowersGrowth
                {...followersProps}
            />
        </div>
    )
}

Tiktok.propTypes = {
    data: PropTypes.object,
}

Tiktok.defaultProps = {
    data: undefined,
}

export default Tiktok
