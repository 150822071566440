import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
// import { useSpring } from '@react-spring/web'

import { convertDate } from 'lib/dates'
import Image from 'components/Image'

import Icon from 'components/Iconly'
// import BoundingClientModal from 'components/BoundingClientModal'
import { convertNumber } from 'lib/numbers'

import BarChart from './ChartBar'
import Tabs from './Tabs'
import AnalyticsTitle from './AnalyticsTitle'
// import FullPagePost from './FullPagePost'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 44,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    largePostsImgWrapper: {
        display: 'flex',
        paddingLeft: 15,
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
    },
    largeImg: {
        marginRight: 10,
        position: 'relative',
    },
    title: {
        marginLeft: 15,
    },
    date: {
        fontSize: 12,
        marginTop: 5,
        transform: 'rotate(90deg)',
        width: 0,
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
    contentItem: {
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItemEngText: {
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: 14,
    },
    barWrapper: {
        filter: 'blur(8px)',
    },
}
const LastPosts = ({ posts, tabs, vendor, username }) => {
    if (!posts.length) return null
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)
    const [ hasLoaded, setHasLoaded ] = useState(false)

    useEffect(() => {
        setTimeout(() => setHasLoaded(true), 4000)
    }, [])

    const blurStyles = [
        'reach',
        'impressions',
        'retention',
        'subGain',
        'subLost',
        'minWatched',
    ].indexOf(activeTab) === -1
        ? {}
        : styles.barWrapper

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <AnalyticsTitle
                    title={`Last ${posts.length} posts`}
                />
            </div>
            {/* <BoundingClientModal
                close={closeModal}
                targetBounds={targetBounds}
                modalOpen={modalOpen}
                itemBorderRadius={10}
                maxHeight={window.innerHeight}
                maxWidth={window.innerWidth}
            >
                <FullPagePost
                    modalOpen={modalOpen}
                    animatedProps={metricsStyleProps}
                    targetPost={targetPost}
                    tabs={tabs}
                    vendor={vendor}
                />
            </BoundingClientModal> */}
            <div style={styles.largePostsImgWrapper}>
                {[...posts].reverse().map((item, idx) => {
                    return (
                        <div
                            style={styles.largeImg}
                            key={item.id}
                            // onClick={(event) => onClickHandler(event, item)}
                        >
                            <div style={{
                                ...styles.contentItem,
                                bottom: 10,
                                right: 10,
                                display: item.impressions || item.views ? 'flex' : 'none',
                            }}>
                                <Icon
                                    type={'Show'}
                                    bold
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                    }}
                                />
                                <div style={{ width: 5 }}/>
                                <div style={styles.contentItemEngText}>
                                    {vendor === 'instagram'
                                        ? convertNumber(item.impressions)
                                        : convertNumber(item.views)
                                    }
                                </div>
                            </div>
                            <div style={{
                                ...styles.contentItem,
                                bottom: 10,
                                left: 10,
                            }}>
                                <Icon
                                    type={'Heart'}
                                    bold
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                    }}
                                />
                                <div style={{ width: 5 }}/>
                                <div style={styles.contentItemEngText}>{convertNumber(item.likes)}</div>
                            </div>
                            <Image
                                height={vendor === 'tiktok'
                                    ? '88.88vw'
                                    : '50vw'
                                }
                                width={
                                    vendor === 'youtube'
                                        ? '88.88vw'
                                        : '50vw'
                                }
                                src={item.pic}
                                fallbackType={vendor === 'tiktok' ? 'storyFallback' : 'contentV1'}
                                borderRadius={10}
                                objectFit={'cover'}
                                alt={`${vendor}-post-${idx}-for-${username}-mediakit`}
                            />
                        </div>
                    )
                })}
            </div>
            <div style={{ height: 30 }}/>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                justifyContent={tabs.length > 2 ? 'space-between' : 'flex-start'}
            />
            <div style={blurStyles}>
                <div style={{ height: 30 }}/>
                <BarChart data={posts} dataKey={activeTab} hideDate/>
                {hasLoaded
                    ? (<div style={styles.postsImageWrapper}>
                        {posts.map((item, idx) => {
                            return (
                                <div
                                    key={item.id}
                                    // onClick={(event) => onClickHandler(event, item)}
                                    style={{
                                        ...styles.imageWrapper,
                                    }}>
                                    <Image
                                        height={29}
                                        width={29}
                                        src={item.pic}
                                        borderRadius={5}
                                        objectFit={'cover'}
                                        fallbackType={vendor === 'tiktok' ? 'storyFallback' : 'contentV1'}
                                        alt={`${username}-post-${idx}-for-${vendor}-mediakit`}
                                    />
                                    <span
                                        style={styles.date}
                                        children={convertDate(item.date)}/>
                                </div>
                            )
                        })}
                    </div>)
                    : null
                }
            </div>
        </div>
    )
}

LastPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    vendor: PropTypes.string.isRequired,
    username: PropTypes.string,
}

LastPosts.defaultProps = {
    posts: [],
    tabs: [],
    username: '',
}

export default LastPosts
