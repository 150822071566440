import PropTypes from 'prop-types'

import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // border: '1px solid #DAE6FD',
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        borderRadius: 10,
        padding: 15,
        width: '33vw',
        maxWidth: 250,
        // marginRight: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        // display: 'inline-block',
    },
    iconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 35,
        fontSize: 18,
        marginBottom: 15,
    },
}

const MetricCard = ({
    label,
    value,
    icon,
    iconBg,
    iconColor,
    blurValue,
}) => {
    return (
        <div style={styles.wrapper}>
            <div style={{
                ...styles.iconWrapper,
                backgroundColor: iconBg && iconBg.length ? iconBg : '#ECFCFF',
                color: iconColor && iconColor.length ? iconColor : '#68ADBA',
            }}>
                <Icon type={icon} bold/>
            </div>
            <div style={{ width: 10 }}/>
            <div>
                <Text
                    children={label}
                    color={'text'}
                    size={'p2'}
                />
                <Text
                    children={value}
                    color={'black'}
                    weight={'bold'}
                    size={'h3'}
                    wrapperStyle={{
                        filter: blurValue ? 'blur(8px)' : '',
                    }}
                />
            </div>
        </div>
    )
}

MetricCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    iconBg: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    blurValue: PropTypes.bool,
}

MetricCard.defaultProps = {
    blurValue: false,
}

export default MetricCard
