import { colors } from 'styles'
import { makePercentage, convertNumber } from 'lib/numbers'

const styles = {
    barsWrapper: {
        padding: '0px 35px',
        overflow: 'hidden',
    },
    title: {
        marginLeft: 35,
    },
    barChartWrapper: {
        marginLeft: -15,
    },
    barWrapper: {
        paddingTop: 20,
        filter: 'blur(8px)',
    },
    barInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    barName: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.black,
    },
    barPercentage: {
        fontSize: 18,
        fontWeight: 500,
    },
    barDataWrapper: {
        height: 15,
        position: 'relative',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 15,
        borderRadius: 5,
        width: '100%',
        position: 'absolute',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 5,
        height: 15,
    },
}

// eslint-disable-next-line
const Bar = ({ name, total, perc, showPercent }) => {
    return (
        <div style={styles.barWrapper}>
            <div style={styles.barInfoWrapper}>
                <div style={styles.barName}>{name}</div>
                <div style={styles.barPercentage}>{showPercent ? makePercentage(perc) : convertNumber(total)}</div>
            </div>
            <div style={styles.barDataWrapper}>
                <div style={{ ...styles.barDataActive, width: makePercentage(perc) }} />
                <div style={styles.barDataBackground} />
            </div>
        </div>
    )
}

export default Bar
