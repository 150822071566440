/* eslint-disable react/prop-types */
import Image from 'components/Image'
import Text from 'components/Text'

import { capitalizeString } from 'lib/strings'
import { convertNumber } from 'lib/numbers'

import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'

const styles = {
    wrapper: {
        marginTop: 80,
    },
    itemWrapper: {
        textDecoration: 'none',
    },
    grid: {
        display: 'grid',
        gridColumnGap: 32,
        gridRowGap: 80,
        gridTemplateColumns: '1fr',
        padding: 16,
    },
    gridItem: {
        position: 'relative',
        background: '#fff',
        borderRadius: 16,
        boxShadow: 'rgba(205, 213, 231, 0.5) 0px 0px 46.6167px',
        padding: 32,
    },
    gridImg: {
        position: 'absolute',
        top: -50,
        left: 32,
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    btn: {
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 10,
        padding: 0,
        width: '100%',
        height: 45,
    },
}

const getVendorIcon = (vendor) => {
    switch (vendor) {
        case 'instagram': return <InstagramIcon size={20}/>
        case 'youtube': return <YoutubeIcon size={20}/>
        case 'tiktok': return <TiktokIcon size={20}/>
        default: return null
    }
}
const Suggestions = ({ data }) => {
    const open = (username) => `${window.location.origin}/${username}`
    return (
        <div style={styles.wrapper}>
            <Text
                weight={'semiBold'}
                wrapperStyle={{ marginBottom: 80, marginLeft: 16 }}
                children={'Similar Influencers'}
            />
            <div style={styles.grid}>
                {data.map(i => {
                    const vendorAlt = i.vendors.join('-')

                    return (
                        <div key={i.id} style={styles.gridItem}>
                            <div style={styles.gridImg}>
                                <Image
                                    src={i.pic}
                                    width={100}
                                    height={100}
                                    alt={`${i.username}-mediakit-for-${vendorAlt}`}
                                />
                            </div>
                            <div style={{ height: 32 }}/>
                            <a style={styles.itemWrapper} href={open(i.username)}>
                                <Text
                                    size={'h5'}
                                    weight={'medium'}
                                    wrapperStyle={{ textDecoration: 'none' }}
                                    children={`@${capitalizeString(i.username)}`}/>
                            </a>
                            <div style={{ height: 32 }}/>
                            <a style={styles.itemWrapper} href={open(i.username)}>
                                <div style={styles.flex}>
                                    <div>
                                        <Text
                                            size={'h3'}
                                            weight={'bold'}
                                            children={convertNumber(i.fanbase)}
                                        />
                                        <Text
                                            size={'p'}
                                            weight={'medium'}
                                            color={'grey2'}
                                            children={'Followers'}
                                        />
                                    </div>
                                    <div>
                                        {i.vendors.map((vendor, idx) => {
                                            return (
                                                <div style={{ marginRight: 8, display: 'inline-block' }} key={idx}>
                                                    {getVendorIcon(vendor)}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </a>
                            <div style={{ height: 30 }}/>
                            <a style={styles.itemWrapper} href={open(i.username)}>
                                <button
                                    style={styles.btn}
                                    children={'View Mediakit'}
                                />
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Suggestions
