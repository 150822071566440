import Image from 'components/Image'

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        marginTop: 60,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        paddingBottom: 40,
        marginBottom: 24,
    },
    title: {
        fontSize: 22,
        textAlign: 'center',
        fontWeight: 600,
    },
    btn: {
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        color: '#fff',
        fontWeight: 600,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 10,
        padding: 0,
        width: 300,
        height: 50,
    },
    subTitle: {
        fontSize: 16,
        color: '#777',
        fontWeight: 500,
        textAlign: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 8,
        marginBottom: 32,
    },
    itemWrapper: {
        textDecoration: 'none',
    },
}
const CallToAction = () => {
    return (
        <div style={styles.wrapper}>
            <div style={{ margin: '0 auto' }}>
                <Image
                    height={56}
                    width={56}
                    borderRadius={10}
                    src={'https://uploads-ssl.webflow.com/6142894e0ccf9376f1bb4393/6178113a66fb645b6f62744c_1-min.png'}
                />
            </div>
            <h2
                style={styles.title}
                children={'Want to create your own social media kit?'}
            />
            <h4 style={styles.subTitle}>
                Be a pro influencer and create your own mediakit for YouTube, Instagram and TikTok
            </h4>
            <a style={styles.itemWrapper} href={'https://mysocial.io/mediakit'}>
                <button
                    style={styles.btn}
                    children={'Create Influencer Mediakit'}
                />
            </a>
        </div>
    )
}

export default CallToAction
