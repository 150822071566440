// import Text from 'components/Text'
import PropTypes from 'prop-types'

const AnalyticsTitle = ({ title, noHeight }) => {
    return (
        <>
            <h3
                color={'black'}
                weight={'bold'}
                size={'h6'}
                children={title}
                style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: 16,
                }}
            />
            { noHeight ? null : <div style={{ height: 30 }}/>}
        </>
    )
}

AnalyticsTitle.propTypes = {
    title: PropTypes.string.isRequired,
    noHeight: PropTypes.bool,
}

AnalyticsTitle.defaultProps = {
    noHeight: false,
}

export default AnalyticsTitle
