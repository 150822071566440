
import PropTypes from 'prop-types'
import MetricCard from './MetricCard2'
import { convertNumber } from 'lib/numbers'
// import { convertNumber } from 'lib/numbers'

const styles = {
    wrapper: {
        // display: 'grid',
        // gridColumnGap: 7.5,
        // gridRowGap: 7.5,
        // gridTemplateColumns: '1fr 1fr',
        // width: '100%',
        padding: 15,
        margin: -15,
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
    metricsWrapper: {
        display: 'grid',
        gridColumnGap: 10,
        gridTemplateColumns: '1fr 1fr 1fr',
        // width: '100%',
        // width: 'fit-content',
    },
}
const InstagramOverview = ({ followers, impressions, interactions }) => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.metricsWrapper}>
                <MetricCard
                    value={convertNumber(followers)}
                    label={'Followers'}
                    icon={'Profile'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
                <MetricCard
                    value={convertNumber(impressions || interactions)}
                    label={'Avg Impressions'}
                    icon={'Show'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                    blurValue
                />
                <MetricCard
                    value={convertNumber(interactions)}
                    label={'Avg Interactions'}
                    icon={'Heart'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
            </div>
        </div>
    )
}

InstagramOverview.propTypes = {
    comments: PropTypes.number,
    impressions: PropTypes.number,
    followers: PropTypes.number,
    engagement: PropTypes.number,
    interactions: PropTypes.number,
    likes: PropTypes.number,
    reach: PropTypes.number,
}

InstagramOverview.defaultProps = {
    comments: 0,
    reach: 0,
    followers: 0,
    engagement: 0,
    likes: 0,
    impressions: 0,
    interactions: 0,
}

export default InstagramOverview
