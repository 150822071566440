import PropTypes from 'prop-types'

import AnalyticsTitle from './AnalyticsTitle'
import MetricGrid from './MetricGrid'
import AudienceCard from './AudienceCard'
import GenderCard from './GenderCard'

const InstagramAudience = ({ genders, ages, cities, countries }) => {
    if ((!genders || !genders.male) && (!ages || !ages.length) && (!cities || !cities.length) && (!countries || !countries.length)) return null
    return (
        <div>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Audience Overview'}/>
            <div>
                <MetricGrid>
                    {genders && genders.male
                        ? <GenderCard
                            data={genders}
                        />
                        : null
                    }
                    {ages && ages.length
                        ? <AudienceCard
                            title={'Age'}
                            data={ages}
                        />
                        : null
                    }
                    {countries && countries.length
                        ? <AudienceCard
                            title={'Countries'}
                            data={countries}
                        />
                        : null
                    }
                    {cities && cities.length
                        ? <AudienceCard
                            title={'Cities'}
                            data={cities}
                        />
                        : null
                    }
                </MetricGrid>
            </div>
        </div>
    )
}

InstagramAudience.propTypes = {
    genders: PropTypes.object,
    ages: PropTypes.array,
    cities: PropTypes.array,
    countries: PropTypes.array,
}

InstagramAudience.defaultProps = {
    genders: {},
    ages: [],
    cities: [],
    countries: [],
}

export default InstagramAudience
