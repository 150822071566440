import PropTypes from 'prop-types'
import { useState } from 'react'

import ChartBar from './ChartBar'
import Tabs from './Tabs3'
import AnalyticsTitle from './AnalyticsTitle'

const styles = {
    wrapper: {
        background: '#fff',
        // padding: '30px',
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
        borderRadius: 15,
        overflow: 'hidden',
    },
    contentWrapper: {
        filter: 'blur(8px)',
    },
    metricCards: {
        display: 'flex',
    },
    barChartWrapper: {
        // marginLeft: -15,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    title: {
        marginLeft: 15,
        marginTop: 15,
        marginBottom: 15,
    },
}

const ProfileOverview = ({ data, tabs, tabsData }) => {
    const [activeTab] = useState(tabs[0] && tabs[0].key)

    if (!data.length) return null

    return (
        <>
            <div style={styles.wrapper}>
                <div style={styles.title}>
                    <AnalyticsTitle
                        title={'Profile Overview'}
                        noHeight
                    />
                </div>
                <div style={styles.contentWrapper}>
                    <div style={styles.line}/>
                    <Tabs
                        tabsData={tabsData}
                        tabs={tabs}
                        setActiveTab={() => null}
                        activeTab={activeTab}
                    />
                    <div style={styles.line} />
                    <div style={{ height: 45 }}/>
                    <div style={styles.barChartWrapper}>
                        <ChartBar data={[...data].reverse().map(i => ({ date: i.date, value: i[activeTab] }))} />
                    </div>
                    <div style={{ height: 30 }}/>
                </div>
            </div>
        </>
    )
}

ProfileOverview.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    tabsData: PropTypes.object,
}

ProfileOverview.defaultProps = {
    tabsData: {},
    growth: 0,
    data: [],
    count: 0,
    tabs: [],
}

export default ProfileOverview
