import PropTypes from 'prop-types'

import Image from 'components/Image'
import { convertNumber } from 'lib/numbers'
import Text from 'components/Text'
import { useState } from 'react'

const styles = {
    outerWrapper: {
        paddingBottom: 30,
        background: '#fff',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 15px',
        textAlign: 'center',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'left',
        width: '100%',
    },
    dotSpace: {
        height: 4,
        width: 4,
        borderRadius: 5,
        background: '#69707E',
        marginLeft: 10,
        marginRight: 10,
    },
}

const ProfileHeader = ({
    pic,
    fullName,
    username,
    fanbase,
    headline,
    country,
    city,
    bio,
}) => {
    const [ limit, setLimit ] = useState(80)
    return (
        <div style={styles.outerWrapper}>
            <div style={{ height: 40 }}/>
            <div style={styles.wrapper}>
                <div style={styles.flex}>
                    <Image
                        src={pic}
                        width={90}
                        height={90}
                        objectFit={'cover'}
                        borderRadius={100}
                        alt={`${username}-influencer-mediakit`}
                    />
                    <div style={{ paddingLeft: 15 }}>
                        <h2
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: 20,
                            }}
                            children={fullName || username}
                        />
                        {headline
                            ? (
                                <>
                                    <div style={{ height: 5 }}/>
                                    <Text
                                        children={headline}
                                        size={'p'}
                                        color={'text'}
                                        weight={'bold'}
                                        wrapperStyle={{ maxWidth: '100%' }}
                                        truncate
                                    />
                                </>
                            )
                            : null}

                        <>
                            <div style={{ height: 8 }}/>
                            <div style={styles.flex}>
                                {country
                                    ? (
                                        <h2
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: '#69707e',
                                                marginTop: 0,
                                                marginBottom: 8,
                                            }}
                                            children={`Influencer from ${country}, ${city}`}
                                        />
                                    )
                                    : null}
                            </div>
                            <Text
                                children={`${convertNumber(fanbase)} fans`}
                                size={'p'}
                                color={'text'}
                                wrapperStyle={{ maxWidth: '100%' }}
                                truncated
                                weight={'semiBold'}
                            />
                        </>
                    </div>
                </div>
                <div style={{ height: 15 }}/>
                {bio
                    ? (
                        <>
                            <div style={{ height: 30 }}/>
                            <div style={styles.labelWrapper}>
                                <Text
                                    size={'h6'}
                                    color={'text'}
                                    wrapperStyle={{ maxWidth: '100%', textAlign: 'center' }}
                                >
                                    {bio.slice(0, limit)}
                                    {bio.length > 80
                                        ? limit === 80
                                            ? <span onClick={() => setLimit(2000)} style={{ fontWeight: 600, fontSize: 14 }}>...read more</span>
                                            : <span onClick={() => setLimit(80)} style={{ fontWeight: 600, fontSize: 14 }}>...read less</span>
                                        : null
                                    }
                                </ Text>
                            </div>
                        </>
                    )
                    : null}
            </div>
        </div>
    )
}

ProfileHeader.propTypes = {
    pic: PropTypes.string,
    fullName: PropTypes.string,
    username: PropTypes.string,
    fanbase: PropTypes.number,
    headline: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    onContact: PropTypes.func,
    openAnalytics: PropTypes.func,
    bio: PropTypes.string,
    email: PropTypes.string,
}

ProfileHeader.defaultProps = {
    pic: null,
    fullName: null,
    username: null,
    fanbase: 0,
    headline: null,
    country: null,
    city: null,
    onContact: () => null,
    openAnalytics: () => null,
    bio: '',
    email: '',
}

export default ProfileHeader
