
import { runQuery } from 'features/feature-network'
import queries from './graphql'

import { setData, setAnalytics, setSuggestions } from './profile.reducer'

const apiToken = process.env.REACT_APP_API_TOKEN

const runQueryWithToken = (query, props) => (dispatch) =>
    dispatch(runQuery(query, { ...props, token: apiToken }))

export const getProfileData = (username) => async (dispatch) => {
    if (window.userInfo) {
        dispatch(setData({
            profile: window.userInfo,
        }))

        dispatch(getSuggestions(username))
        return window.userInfo
    }

    const res = await dispatch(runQueryWithToken(queries.getPageData, {
        username: username,
        token: apiToken,
    }))
    const { profile } = res.data.mainV2
    dispatch(setData({ profile }))

    dispatch(getSuggestions(username))

    return profile
}

export const getAnalytics = ({ vendor, username, channelId }) => async (dispatch) => {
    let query = null

    const key = vendor === 'overview'
        ? vendor
        : channelId

    switch (vendor) {
        case 'overview':
            query = queries.analyticsOverview
            break
        case 'tiktok':
            query = queries.analyticsTiktok
            break
        case 'youtube':
            query = queries.analyticsYoutube
            break
        case 'instagram':
            query = queries.analyticsInstagram
            break
        default:
            throw new Error('unknown query')
    }

    try {
        const res = await dispatch(runQueryWithToken(query, { username, channelId }))
        const data = res.data.mainV2.data

        dispatch(setAnalytics(key, data))
        return res.data.mainV2.data
    } catch (err) {
        dispatch(setAnalytics(key, null))
    }
}

export const getSuggestions = (username) => async (dispatch) => {
    const res = await dispatch(runQueryWithToken(queries.getSuggestions, {
        username,
        token: apiToken,
    }))

    dispatch(setSuggestions(res.data.mainV2.suggestions))
}
