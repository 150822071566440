
import PropTypes from 'prop-types'
import TikTokOverview from '../components/YouTubeOverview'
import LastPosts from '../components/LastPosts'
import FollowersGrowth from '../components/FollowersGrowth'
import Loader from '../components/Loader'
import NoAnalytics from '../components/NoAnalytics'

const Tiktok = ({ data }) => {
    if (data === undefined) return <Loader />
    if (data === null) return <NoAnalytics vendor={'TikTok'} />

    const postsProps = {
        posts: data.postsList.slice(0, 10).reverse(),
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
            { key: 'shares', label: 'Shares' },
        ],
    }

    const followersProps = {
        growth: data.followersGrowth,
        count: data.followers,
        data: data.followersGrowthList,
        vendor: 'tiktok',
    }

    return (
        <div style={{ padding: 15, overflow: 'visible' }}>
            <div style={{ height: 15 }}/>
            <TikTokOverview
                views={data.views}
                interactions={data.interactions}
                followers={data.followers}
            />

            {!data.postsList.length
                ? null
                : (
                    <>
                        <div style={{ height: 30 }}/>
                        <LastPosts
                            {...postsProps}
                            vendor={'tiktok'}
                            username={data.name}
                        />
                    </>
                )}
            <div style={{ height: 30 }}/>
            <FollowersGrowth {...followersProps}/>
        </div>
    )
}

Tiktok.propTypes = {
    data: PropTypes.object,
}

Tiktok.defaultProps = {
    data: undefined,
}

export default Tiktok
