
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useEffect, useState } from 'react'

import ErrorUI from './ErrorUI'
import LoadingUI from './LoadingUI'
import MobileLayout from './MobileLayout'
import DesktopLayout from './DesktopLayout'

const ProfileUIV2 = ({
    profile,
    error,
    analytics,
    onContact,
    getAnalytics,
    params,
    suggestions,
}) => {
    if (!error && !profile) return <LoadingUI />
    if (error) return <ErrorUI />

    const isDesktop = useMediaQuery('(min-width: 992px)')
    const isMobile = useMediaQuery('(max-width: 991px)')

    const [ activeChannel, setActiveChannel ] = useState(
        params.vendor && params.channelId
            ? profile.channels.find(i => (i.id === params.channelId) && (i.vendor === params.vendor)) || profile.channels[0]
            : profile.channels[0],
    )

    useEffect(() => {
        if (analytics[activeChannel.id]) return
        getAnalytics(activeChannel.id, activeChannel.vendor)
    }, [])

    if (isDesktop) {
        return (
            <DesktopLayout
                profile={profile}
                analytics={analytics}
                onContact={onContact}
                activeChannel={activeChannel}
                suggestions={suggestions}
                setActiveChannel={(i) => {
                    setActiveChannel(i)
                    getAnalytics(i.id, i.vendor)
                }}
            />
        )
    }
    if (isMobile) {
        return (
            <MobileLayout
                profile={profile}
                analytics={analytics}
                onContact={onContact}
                activeChannel={activeChannel}
                suggestions={suggestions}
                setActiveChannel={(i) => {
                    setActiveChannel(i)
                    getAnalytics(i.id, i.vendor)
                }}
            />
        )
    }
    return null
}

ProfileUIV2.propTypes = {
    onContact: PropTypes.func,
    getAnalytics: PropTypes.func,
    analytics: PropTypes.object,
    profile: PropTypes.object,
    error: PropTypes.string,
    params: PropTypes.object,
    suggestions: PropTypes.array,
}

ProfileUIV2.defaultProps = {
    getAnalytics: () => null,
    onContact: () => null,
    profile: null,
    error: null,
    analytics: {},
    params: {},
    suggestions: [],
}

export default ProfileUIV2
