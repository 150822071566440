// import Text from 'components/Text'
import PropTypes from 'prop-types'

const AnalyticsTitle = ({ title }) => {
    return (
        <>
            <h3
                style={{
                    fontSize: 22,
                    marginTop: 0,
                    marginBottom: 0,
                }}
                children={title}
            />
            <div style={{ height: 35 }}/>
        </>
    )
}

AnalyticsTitle.propTypes = {
    title: PropTypes.string.isRequired,
}

export default AnalyticsTitle
