
import { Switch, Route } from 'react-router-dom'
import ReactGA from 'react-ga'

import { HomePage, ProfilePage } from './pages'

ReactGA.initialize('UA-102112932-4')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={'/:vendor/:username/:channelId'}
                    component={ProfilePage}
                />
                <Route
                    exact
                    path={'/:username'}
                    component={ProfilePage}
                />
                <Route
                    path={'/'}
                    component={HomePage}
                />
            </Switch>
        </>
    )
}

export default App
