import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getProfileData, getAnalytics } from 'features/feature-profile-v2'

import ProfileUIV2 from './components/ProfileUIV2'

const mapState = ({ profile }, { match }) => ({
    profile: profile.profile,
    analytics: profile.analytics,
    params: match.params,
    suggestions: profile.suggestions,
})

const mapDispatch = (dispatch, { match, history }) => ({
    load: () => dispatch(getProfileData(match.params.username)),
    onContact: (email) => window.open(`mailto:${email}`),
    loadAnalytics: (props) => dispatch(getAnalytics(props)),
})

const ProfilePageV2 = ({ load, onContact, loadAnalytics, profile, analytics, params, suggestions }) => {
    const [ error, setError ] = useState(null) // eslint-disable-line

    useEffect(async () => {
        try {
            const res = await load()
            if (!res.channels.length) throw new Error('nothing to view here')
        } catch (err) {
            setError(err.message)
        }
    }, [])

    const getAnalytics = (channelId, vendor) => {
        if (analytics[channelId]) return

        loadAnalytics({
            vendor,
            channelId,
            username: profile.username,
        })
    }

    return (
        <ProfileUIV2
            error={error}
            profile={profile}
            analytics={analytics}
            getAnalytics={getAnalytics}
            onContact={() => onContact(profile.email)}
            params={params}
            suggestions={suggestions}
        />
    )
}

ProfilePageV2.propTypes = {
    load: PropTypes.func.isRequired,
    onContact: PropTypes.func.isRequired,
    loadAnalytics: PropTypes.func.isRequired,
    analytics: PropTypes.object.isRequired,
    profile: PropTypes.object,
    params: PropTypes.object.isRequired,
    suggestions: PropTypes.array.isRequired,
}

ProfilePageV2.defaultProps = {
    profile: null,
}

export default connect(mapState, mapDispatch)(ProfilePageV2)
