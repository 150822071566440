
import PropTypes from 'prop-types'

import { convertNumber } from 'lib/numbers'

import ChartBarGrowth from './ChartBarGrowth'
import MetricCards from './MetricCards'
import MetricCard from './MetricCardHorizontal'
import AnalyticsTitle from './AnalyticsTitle'

const styles = {
    wrapper: {
        background: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 15,
        // borderTopRightRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    metricCards: {
        display: 'flex',
        padding: 15,
        justifyContent: 'space-between',
    },
    barChartWrapper: {
        marginLeft: 10,
        marginRight: 10,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    title: {
        marginLeft: 15,
        marginBottom: 15,
    },
}

const FollowersGrowth = ({ data, growth, count, hideCards }) => {
    if (!data.length) return null

    return (
        <div style={{
            ...styles.wrapper,
        }}>
            <div style={styles.title}>
                <AnalyticsTitle
                    title={'Followers Growth'}
                    noHeight
                />
            </div>
            <div style={styles.line}/>
            <div style={styles.metricCards}>
                <MetricCard
                    value={convertNumber(count)}
                    label={'Total Followers'}
                    icon={'Profile'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}

                />
                <div style={{ width: 30 }}/>
                <MetricCard
                    value={growth > 0 ? `+${convertNumber(growth)}` : convertNumber(growth)}
                    label={'vs last 30d'}
                    icon={growth > -1 ? 'Arrow-Up' : 'Arrow-Down'}
                    iconBg={growth > -1 ? '#E5F5EF' : '#FFF3F5'}
                    iconColor={growth > -1 ? '#00985F' : '#FF274B'}
                />
            </div>
            <div style={styles.line}/>
            {hideCards
                ? null
                : (
                    <>
                        <div style={{ height: 15 }} />
                    </>
                )}
            <div style={styles.barChartWrapper}>
                <ChartBarGrowth data={data} />
            </div>
            <div style={{ height: 15 }} />
        </div>
    )
}

FollowersGrowth.propTypes = {
    growth: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    count: PropTypes.number,
    hideCards: PropTypes.bool,
}

MetricCards.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    count: PropTypes.number,
}

MetricCards.defaultProps = {
    data: [],
    count: 0,
}

FollowersGrowth.defaultProps = {
    growth: 0,
    data: [],
    count: 0,
    hideCards: false,
}

export default FollowersGrowth
