
export default {
    getPageData: `
        query getPageData ($username:String!, $token:String) {
            mainV2 (token: $token) {
                profile: getProfile (username: $username)
            }
        }
    `,
    getSuggestions: `
        query getSuggestions ($username:String!, $token:String) {
            mainV2 (token: $token) {
                suggestions: getSuggestions (username: $username)
            }
        }
    `,
    analyticsTiktok: `
        query analyticsTiktok ($username:String!, $channelId: ID!, $token:String) {
            mainV2 (token: $token) {
                data: analyticsTiktok (username: $username, channelId: $channelId)
            }
        }
    `,
    analyticsInstagram: `
        query analyticsInstagram ($username:String!, $channelId: ID!, $token:String) {
            mainV2 (token: $token) {
                data: analyticsInstagram (username: $username, channelId: $channelId)
            }
        }
    `,
    analyticsYoutube: `
        query analyticsYoutube ($username:String!, $channelId: ID!, $token:String) {
            mainV2 (token: $token) {
                data: analyticsYoutube (username: $username, channelId: $channelId)
            }
        }
    `,
}
