import PropTypes from 'prop-types'
import { useState } from 'react'
// import { useSpring } from '@react-spring/web'

import { convertDate } from 'lib/dates'
import ImageAlbum from 'components/ImageAlbum'

import Icon from 'components/Iconly'
import { convertNumber } from 'lib/numbers'

import BarChart from '../ChartBar'
import Tabs from '../Tabs'
// import BoundingClientModal from 'components/BoundingClientModal'
import AnalyticsTitle from '../AnalyticsTitle'

// import FullPageStories from '../FullPageStories'

import { styles } from './LastStories.style'

const LastStories = ({ posts, tabs, username }) => {
    if (!posts || !posts.length) return null
    const [activeTab] = useState(tabs[0] && tabs[0].key)
    // const [ modalOpen, setModalOpen ] = useState(false)
    // const [ targetPosts, setTargetPosts ] = useState([])
    // const [ targetBounds, setTargetBounds ] = useState({
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     top: 0,
    //     width: 0,
    //     height: 0,
    // })

    // const [ { opacity, height, width }, api2 ] = useSpring(() => ({
    //     opacity: 0,
    //     height: 0,
    //     width: 0,
    // }))

    // const onClickHandler = useCallback((event, item) => {
    //     setTargetPosts([...item.list].reverse())
    //     const boundingClient = event.target.getBoundingClientRect()
    //     setTargetBounds({
    //         width: boundingClient.width,
    //         top: boundingClient.top - 10,
    //         bottom: boundingClient.bottom + 20,
    //         right: boundingClient.right,
    //         left: boundingClient.left,
    //         height: boundingClient.height + 20,
    //     })
    //     setTimeout(() => setModalOpen(true), 10)
    //     api2.start({
    //         opacity: 1,
    //         height: window.innerHeight - 60,
    //         width: window.innerHeight * 0.5625,
    //     })
    // }, [])

    // const closeModal = () => {
    //     api2.start({
    //         opacity: 0,
    //         height: targetBounds.height,
    //         width: targetBounds.width,
    //     })
    //     setTimeout(() => {
    //         setModalOpen(false)
    //         setTargetPosts([])
    //     }, 450)
    // }

    // handle scroll indicators

    return (
        <>
            <div style={{ height: 60 }}/>
            <div style={styles.wrapper}>
                <div style={styles.title}>
                    <AnalyticsTitle title={`Last ${posts.length} Days of Stories`} />
                </div>
                {/* <BoundingClientModal
                    close={closeModal}
                    targetBounds={targetBounds}
                    modalOpen={modalOpen}
                    itemPadding={30}
                    itemBorderRadius={15}
                    maxHeight={window.innerHeight}
                    maxWidth={window.innerWidth }
                    wrapperStyles={styles.fullPageWrapper}
                >
                    <FullPageStories
                        isVisible={modalOpen}
                        mediaList={targetPosts}
                        opacity={opacity}
                        height={height}
                        width={width}
                        top={top}
                    />
                </BoundingClientModal> */}
                <div >
                    <div style={styles.scrollWrapper}>
                        <div style={styles.largePostsImgWrapper} >
                            {posts.slice(0, 3).map((item, idx) => {
                            // let hoverEff = null
                                return (
                                    <div key={idx}>
                                        <div
                                        // ref={e => (hoverEff = e)}
                                            style={styles.largeImg}
                                        // onMouseEnter={() => {
                                        //     hoverEff.style.opacity = 0.8
                                        //     hoverEff.style.transform = 'scale(0.95)'
                                        // }}
                                        // onMouseLeave={() => {
                                        //     hoverEff.style.opacity = 1
                                        //     hoverEff.style.transform = 'scale(1)'
                                        // }}
                                        // onClick={(event) => onClickHandler(event, item)}
                                        >
                                            <div style={{
                                                ...styles.contentItem,
                                                bottom: 15,
                                                left: 15,
                                                zIndex: 4,
                                                display: item.impressions || item.views ? 'flex' : 'none',
                                            }}>
                                                <Icon
                                                    type={'Show'}
                                                    bold
                                                    style={{
                                                        color: '#FFFFFF',
                                                        fontSize: 15,
                                                    }}
                                                />
                                                <div style={{ width: 5 }}/>
                                                <div style={styles.contentItemEngText}>
                                                    {convertNumber(item.reach)}
                                                </div>
                                            </div>
                                            <div style={{
                                                ...styles.contentItem,
                                                bottom: 15,
                                                right: 15,
                                                zIndex: 4,
                                                display: 'flex',
                                            }}>
                                                <Icon
                                                    type={'Image'}
                                                    bold
                                                    style={{
                                                        color: '#FFFFFF',
                                                        fontSize: 15,
                                                    }}
                                                />
                                                <div style={{ width: 5 }}/>
                                                <div style={styles.contentItemEngText}>
                                                    {item.count}
                                                </div>
                                            </div>
                                            <ImageAlbum
                                                height={472}
                                                width={266}
                                                src={item.list[item.list.length - 1].pic}
                                                borderRadius={15}
                                                fallbackType={'contentV1'}
                                                objectFit={'cover'}
                                                alt={`instagram-stories-${idx}-for-${username}-in-mediakit`}
                                            />
                                        </div>
                                        <div
                                            style={styles.date2}
                                            children={convertDate(item.day)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ height: 40 }}/>
                    <div style={{ filter: 'blur(8px)' }}>
                        <Tabs
                            tabs={tabs}
                            setActiveTab={() => null}
                            activeTab={activeTab}
                        />
                        <div style={{ height: 30 }}/>
                        <BarChart data={posts} dataKey={activeTab} hideDates/>
                    </div>
                </div>
            </div>
        </>
    )
}

LastStories.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    username: PropTypes.string.isRequired,
    vendor: PropTypes.string.isRequired,
}

LastStories.defaultProps = {
    posts: [],
    tabs: [],
}

export default LastStories
