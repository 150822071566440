import PropTypes from 'prop-types'

import Header from './Header'
import Analytics from './Analytics'
import ProfileHeader from './ProfileHeader'
import Suggestions from './Suggestions'
import CallToAction from './CallToAction'

const styles = {
    wrapper: {
        // height: '100%',
        width: '100%',
        // overflow: 'scroll',
        minHeight: '100vh',
        paddingBottom: 100,
        background: 'linear-gradient(94.16deg, #F4F6F9 0.18%, #F5F7FA 93.48%)',
    },
    containerWrapper: {
        width: '100%',
        display: 'flex',
        // alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: 80,
        paddingLeft: 30,
        paddingRight: 30,
    },
    containerInnerWrapper: {
        maxWidth: 1250,
        minWidth: 1024,
        width: 1260,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    headerWrapper: {
        width: 340,
        position: 'sticky',
        top: 130,
    },
    analyticsWrapper: {
        maxWidth: 800,
        minWidth: 800,
    },
}

const DesktopLayout = ({ profile, analytics, onContact, activeChannel, setActiveChannel, suggestions }) => {
    return (
        <div style={styles.wrapper}>
            <Header
                channels={profile.channels}
                setActiveChannel={setActiveChannel}
                activeChannel={activeChannel}
            />
            <div style={styles.containerWrapper}>
                <div style={styles.containerInnerWrapper}>
                    <div style={styles.headerWrapper}>
                        <ProfileHeader
                            pic={profile.pic}
                            name={profile.fullName || profile.username}
                            fanbase={profile.fanbase}
                            headline={profile.headline}
                            country={profile.country && profile.city ? (profile.country + ', ' + profile.city) : null}
                            onContact={onContact}
                            bio={profile.bio}
                            email={profile.email}
                            username={profile.username}
                        />
                    </div>
                    <div style={{ width: 90, minWidth: 15 }}/>
                    <div style={styles.analyticsWrapper}>
                        <Analytics
                            data={analytics[activeChannel.id]}
                            vendor={activeChannel.vendor}
                        />
                        {analytics[activeChannel.id] && <Suggestions data={suggestions}/>}
                        {analytics[activeChannel.id] && <CallToAction />}
                    </div>
                </div>
            </div>
        </div>
    )
}

DesktopLayout.propTypes = {
    profile: PropTypes.object,
    analytics: PropTypes.object,
    onContact: PropTypes.func,
    activeChannel: PropTypes.object,
    setActiveChannel: PropTypes.func,
    suggestions: PropTypes.array,
}

DesktopLayout.defaultProps = {
    profile: {},
    analytics: {},
    onContact: () => null,
    activeChannel: {},
    setActiveChannel: () => null,
    suggestions: [],
}

export default DesktopLayout
